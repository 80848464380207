<template>
  <div
    class="is-flex is-align-content-center is-justify-content-space-between form-nav has-text-centered"
  >
    <div class="logo is-flex is-align-items-center">
      <span class="powered-by" v-html="$t('powered_by')"></span>
    </div>
    <div class="nav is-flex is-align-content-center">
      <div
        class="is-hidden-touch survey-progress is-flex is-flex-direction-column is-justify-content-flex-end mr-4"
      >
        <span class="is-align-self-flex-start is-size-7">{{
          $t("progress", { percent: this.percentage })
        }}</span>
        <b-progress
          :max="maxValue"
          :value="currentValue"
          size="is-tiny"
          type="is-dark-green"
        >
        </b-progress>
      </div>
      <div class="mr-1">
        <b-button
          :disabled="loading || !backable"
          :loading="loading"
          @click="$emit('back')"
          outlined
          type="is-primary"
          v-if="!hideBackBtn"
        >
          <b-icon icon="arrow-up"></b-icon>
        </b-button>
      </div>
      <div class="ml-1">
        <b-button
          :disabled="!nextable"
          :loading="loading"
          native-type="submit"
          outlined
          type="is-primary"
        >
          <b-icon icon="arrow-down"></b-icon>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormNav",
  props: {
    loading: {
      type: Boolean,
    },
    backable: {
      type: Boolean,
    },
    nextable: {
      type: Boolean,
    },
    hideBackBtn: {
      type: Boolean,
    },
    backText: {
      type: String,
      default: "Back",
    },
    nextText: {
      type: String,
      default: "Next",
    },
    current: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    surveyLevel: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percentage() {
      const percentage = (this.currentValue / this.maxValue) * 100;
      return percentage.toFixed(0);
    },
    currentValue() {
      return this.surveyLevel === 2 ? this.current + this.max : this.current
    },
    maxValue() {
      return this.surveyLevel === 0 ? this.max : this.max * 2
    }
  },
};
</script>
