<template>
  <div class="column form-submit">
    <div class="mr-2" v-if="!hideBackBtn">
      <b-button
        outlined
        @click="$emit('back')"
        :disabled="loading || !backable"
        :loading="loading"
        >{{ $t("btn.previous") }}
      </b-button>
    </div>
    <div class="is-flex is-align-items-center">
      <b-button
        :disabled="!nextable"
        :loading="loading"
        class="mr-2"
        native-type="submit"
        type="is-primary"
      >
        {{ nextText }}
      </b-button>
      <!-- <span v-html="$t('btn.press_enter')" class="is-hidden-touch"></span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "MainNav",
  props: {
    loading: {
      type: Boolean,
    },
    backable: {
      type: Boolean,
    },
    nextable: {
      type: Boolean,
    },
    hideBackBtn: {
      type: Boolean,
    },
    current: {
      type: Number,
      default: 0,
    },
    nextText: {
      type: String,
      default: "Next",
    },
    max: {
      type: Number,
      default: 100,
    },
  },
};
</script>
