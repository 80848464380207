export const CustomizedScriptMixin = {
  methods: {
    /* eslint-disable no-unused-vars */
    async preload(...args) {
      try {
        if (!this.qOpts?.customized_script?.is_enabled || !this.qOpts?.customized_script?.config?.script) return;
        const functionString = this.qOpts.customized_script.config.script;

        return await (async(args) => {
          const self = args[0];
          const result = args[1];
          const question = self.question
          const lastAction = args[2];
          const action = lastAction === "next-page" ? "move-next" : "move-back";
          return eval("(async () => {" + functionString + "\n})()")
        })(args);

      } catch (error) {
        console.log(error);
        //ignore
      }
    }
    /* eslint-enable no-unused-vars */
  },
};