import { SELECT_LANGUAGE } from "@/constants/question-type";
import { getSurveyTemplate } from "@/api/response";
import { mapMutations } from "vuex";
import { SET_SURVEY } from "../store";

export const TemplateMixin = {
  data() {
    return {
      error: null,
      changes: {},
      survey: null,
    };
  },
  computed: {
    langFromQuestion() {
      const selectLangQuestion = this.questions?.find(
        (question) => question?.type === SELECT_LANGUAGE
      );
      return this.changes[selectLangQuestion?.qid];
    },
    lang() {
      return (
        this.langFromQuestion ||
        this.$route.query?.lang ||
        this.survey?.default_language
      );
    },
    showQuestionCode() {
      return this.survey?.show_question_code;
    },
    showAnswerCode() {
      return this.survey?.show_answer_code;
    },
  },
  methods: {
    ...mapMutations([SET_SURVEY]),
    onChange(data) {
      this.changes = { ...this.changes, ...data };
    },
    async getSurveyTemplate() {
      const {
        params: { code },
        query: { lang },
      } = this.$route;
      const {
        data: { data: survey },
      } = await getSurveyTemplate(code);
      this[SET_SURVEY](survey);
      this.survey = survey;
      if (lang && !survey?.supported_languages.includes(lang)) {
        this.error = {
          code: 400,
          message: this.$t("errors.LANGUAGE_NOT_SUPPORTED"),
        };
      }
    },
  },
};
